import React from "react";
import { CheckCircleIcon } from '@heroicons/react/solid';
import {ExclamationIcon} from "@heroicons/react/outline";

const Message = (props) => {
    if (!props.text) {
        return null;
    }

    if (props.type === 'error') {
        return (
            <div className="">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                        <div className="rounded-md bg-red-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <ExclamationIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <h3 data-test="message-error-text" className="text-sm font-medium text-red-800">{props.text}</h3>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.type === 'warning') {
        return (
            <div className="">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <div className="rounded-md bg-orange-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <ExclamationIcon className="h-6 w-6 text-orange-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <h3 data-test="message-warning-text" className="text-sm font-medium text-orange-800">{props.text}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="">
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">

        <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 data-test="message-success-text" className="text-sm font-medium text-green-800">{props.text}</h3>
                </div>
            </div>
        </div>

            </div>
        </div>
    )
};

export default Message;
