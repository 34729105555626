import React, { Fragment, useEffect, useState } from 'react'
import {useUser} from "../User/user";
import { Amplify, API } from 'aws-amplify'
import awsConfig from "../../aws-exports-adjusted";
import {acceptTerms} from "../../graphql/mutations";
import {listXeroOnboardings} from "../../graphql/queries";
import TermLink from "../Term/TermLink";
import LoadingContent from "../Loading/LoadingContent";
import { useNavigate } from 'react-router-dom';
Amplify.configure(awsConfig);

const Dashboard = (props) => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [isLoadingOnboarding, setIsLoadingOnboarding] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [connectionCreated, setConnectionCreated] = useState(false);
    const [planAdded, setPlanAdded] = useState(false);
    const [apiCredentialsCreated, setApiCredentialsCreated] = useState(false);
    const [isXeroSignUpUser, setIsXeroSignUpUser] = useState(false);
    
    useEffect(() => {
        fetchOnboarding();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchOnboarding() {
        setIsLoadingOnboarding(true);
        try {
            const onboardingData = await API.graphql({
                    query: listXeroOnboardings,
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                }
            );
            if (onboardingData.data.listXeroOnboardings.items.length > 0) {
                setTermsAccepted(onboardingData.data.listXeroOnboardings.items[0].termsAccepted);
                setConnectionCreated(onboardingData.data.listXeroOnboardings.items[0].connectionCreated);
                setPlanAdded(onboardingData.data.listXeroOnboardings.items[0].planAdded);
                setApiCredentialsCreated(onboardingData.data.listXeroOnboardings.items[0].apiCredentialsCreated);
                setIsXeroSignUpUser(onboardingData.data.listXeroOnboardings.items[0].usedSignUpWithXero);
            }
        } catch (err) {
            console.log('error fetching accepted terms')
        } finally {
            setIsLoadingOnboarding(false);
        }
    }

    const handleAccept = async(e) => {
        if (e.target.checked) {
            try {
                await API.graphql({
                    query:    acceptTerms,
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                setTermsAccepted(true);
            } catch (err) {
                const consolidatedErrors = err.errors.map(function(elem){return elem.message;}).join(",");
                console.log(consolidatedErrors);
            } finally {
            }
        }
    }

    const handleAcceptTermsFromModal = async(setOpen, setIsAccepting) => {
        try {
            await API.graphql({
                query:    acceptTerms,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            setTermsAccepted(true);
        } catch (err) {
            const consolidatedErrors = err.errors.map(function(elem){return elem.message;}).join(",");
            console.log(consolidatedErrors);
        } finally {
            setOpen(false);
            setIsAccepting(false);
        }
    }
    const credentials = user.attributes? (user.attributes.sub.split('-')[0]+':'+user.attributes.sub.split('-')[1]):'';
    const installationInstructionsForXeroSignUps = isXeroSignUpUser ?
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <h2 className="leading-6 font-semibold text-gray-900">Installation Instructions</h2>
            <div className="block mt-4">
                From a command line in your Magento root folder run
            </div>
            <div className="block bg-gray-100 m-4 p-4 text-sm">
                <code className="whitespace-pre-line overflow-hidden">
                    1. composer config repositories.foomanconnect composer https://{credentials}@repo-connect.fooman.com{"\n"}
                    2. composer require fooman/connect-sub-m2:^8.0{"\n"}
                    3. bin/magento module:enable Fooman_ConnectLicenseSub Fooman_Connect{"\n"}
                    4. bin/magento setup:upgrade{"\n"}
                </code>
            </div>

            <div className="block mt-4">
                If you are using Production Mode please also run
            </div>
            <div className="block bg-gray-100 m-4 p-4 text-sm">
                <code className="whitespace-pre-line overflow-hidden">
                5. bin/magento setup:static-content:deploy{"\n"}
                6. bin/magento setup:di:compile{"\n"}
                </code>
            </div>
            <div>
                Once installed please follow our setup guide to configure the integration to your requirements: <br/>
                <button
                    type="button"
                    data-test="view-setup-guide"
                    onClick={() => window.location ='https://magento2-support.fooman.co.nz/article/1299-2-enter-api-credentials'}
                    className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm  hover:text-white hover:bg-gray-600 bg-foomanyellow text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    View Setup Guide
                </button>
            </div>
        </div>
        : null;

    const content = isLoadingOnboarding ?
        <LoadingContent/>:
        <div className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
            <div className="relative flex items-start py-4">
                <div className="min-w-0 flex-1 text-sm">
                    <label className="font-medium text-gray-700 select-none">
                        Accept our <TermLink termsAccepted={termsAccepted} acceptTermsHandler={handleAcceptTermsFromModal}/>
                    </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                    <input
                        id="step-1"
                        name="step-1"
                        type="checkbox"
                        checked={termsAccepted}
                        disabled={termsAccepted}
                        onChange={handleAccept}
                        className="focus:ring-gray-500 h-7 w-7 text-gray-600 border-gray-300 rounded"
                    />
                </div>
            </div>
            <div className="relative flex items-start py-4">
                <div className="min-w-0 flex-1 text-sm">
                    <label htmlFor="step-2" className="font-medium text-gray-700 select-none">
                        <a className="underline" href="/connections" onClick={() => navigate('/connections')}>Connect to your Xero organisation</a>
                    </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                    <input
                        id="step-2"
                        name="step-2"
                        type="checkbox"
                        checked={connectionCreated}
                        disabled={true}
                        className="focus:ring-gray-500 h-7 w-7 text-gray-600 border-gray-300 rounded"
                    />
                </div>
            </div>
            <div className="relative flex items-start py-4">
                <div className="min-w-0 flex-1 text-sm">
                    <label htmlFor="step-3" className="font-medium text-gray-700 select-none">
                        <a className="underline" href="/plans" onClick={() => navigate('/plans')}>Add a plan</a>
                    </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                    <input
                        id="step-3"
                        name="step-3"
                        type="checkbox"
                        checked={planAdded}
                        disabled={true}
                        className="focus:ring-gray-500 h-7 w-7 text-gray-600 border-gray-300 rounded"
                    />
                </div>
            </div>
            <div className="relative flex items-start py-4">
                <div className="min-w-0 flex-1 text-sm">
                    <label htmlFor="step-4" className="font-medium text-gray-700 select-none">
                        <a className="underline" href="/apikeys" onClick={() => navigate('/apikeys')}>Create API Credentials</a>
                    </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                    <input
                        id="step-4"
                        name="step-4"
                        type="checkbox"
                        checked={apiCredentialsCreated}
                        disabled={true}
                        className="focus:ring-gray-500 h-7 w-7 text-gray-600 border-gray-300 rounded"
                    />
                </div>
            </div>
        </div>;

    return (
        <Fragment>
            <header className="bg-white">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg leading-6 font-semibold text-gray-900">Dashboard</h1>
                </div>
            </header>
            <main>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="px-4 py-4 sm:px-0 w-1/2">
                        <div className="bg-white" >
                            <p>Hi {user.attributes?user.attributes.given_name:''}, let's get you set up as quickly as possible.</p>
                        </div>
                        {content}
                    </div>
                </div>
                {installationInstructionsForXeroSignUps}
            </main>
        </Fragment>
    );
}

export default Dashboard;