import React, { Fragment } from 'react';

const AddPlan = (props) => {
    return (
        <Fragment>
            <div className="flex items-start space-x-4">
                <div className="min-w-0 flex-1 px-20 py-6">
                    <form action="#" className="relative">
                        <div
                            className="border border-gray-200 rounded-sm overflow-hidden focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200">
                            <label htmlFor="foomanSerialNumber" className="sr-only">Serial Number</label>
                            <textarea rows={3}
                                      name="foomanSerialNumber"
                                      id="foomanSerialNumber"
                                      data-test="plan-serialnumber-input-textarea"
                                      onChange={event => props.onChangeHandler('foomanSerialNumber', event.target.value)}
                                      value={props.value}
                                      className="block w-full py-3 px-3 border-0 resize-none focus:ring-0 focus:outline-none sm:text-sm"
                                      placeholder="Enter your serial number to add an additional plan."/>
                            <div className="py-2" aria-hidden="true">
                                <div className="py-px">
                                    <div className="h-9"></div>
                                </div>
                            </div>
                        </div>

                        <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                            <div className="flex-shrink-0">
                                <button
                                    type="button"
                                    data-test="plan-add-button"
                                    onClick={() => props.handleAddPlanClick()}
                                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm  hover:text-white hover:bg-gray-600 bg-foomanyellow text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
}

export default AddPlan;