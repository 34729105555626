import awsConfig from "./aws-exports";
const redirectLink = process.env.REACT_APP_COOKIE_DOMAIN ? "https://"+process.env.REACT_APP_COOKIE_DOMAIN : 'http://localhost:3000/';

const oauthSection = {
    ...awsConfig.oauth,
    redirectSignIn: redirectLink,
    redirectSignOut: redirectLink,
    domain: process.env.REACT_APP_COOKIE_DOMAIN ? 'auth.'+process.env.REACT_APP_COOKIE_DOMAIN : 'auth.connect-dev.fooman.com'
};
const updatedAwsConfig = {
    ...awsConfig,
    oauth: {
        ...oauthSection,
    },
    Auth: {
        ...awsConfig,
        oauth: {
            ...oauthSection,
        },
        cookieStorage: {
            domain: process.env.REACT_APP_COOKIE_DOMAIN ? process.env.REACT_APP_COOKIE_DOMAIN : 'localhost',
            path: "/",
            expires: 1,
            sameSite: "strict",
            secure: process.env.REACT_APP_COOKIE_DOMAIN ? true : false,
        }
    }
    //storage: sessionStorage
}

export default updatedAwsConfig;