import React, {Fragment, useState, useEffect, useCallback} from 'react';
import { Amplify, API } from 'aws-amplify';
import awsConfig from "../../aws-exports-adjusted";
import CopyButton from "../CopyButton/copyButton";
import {planByOwner, xeroConnectionByOwner, xeroSiteConnectionByOwner} from "../../graphql/queries";
import LoadingContent from "../Loading/LoadingContent";
import AddSiteConnection from "../SiteConnection/AddSiteConnection";
import {addSiteConnection, removeSiteConnection} from "../../graphql/mutations";
import SiteConnectionRemoveButton from "../SiteConnection/SiteConnectionRemoveButton";
import Message from "../Message";
import {useUser} from "../User/user";
Amplify.configure(awsConfig);

const Sites = (props) => {
    const [isLoadingSites, setIsLoadingSites] = useState(false);
    const [listErrorState, setListErrorState] = useState('');
    const [sites, setSites] = useState([]);
    const [isLoadingConnections, setIsLoadingConnections] = useState(false);
    const [connections, setConnections] = useState([]);
    const [isLoadingPlans, setIsLoadingPlans] = useState(false);
    const [plans, setPlans] = useState([]);
    const {user} = useUser();

    const fetchSites = useCallback(async () => {
        if (sites.length === 0) {
            setIsLoadingSites(true);
        }
        try {
            const planData = await API.graphql({
                    query: xeroSiteConnectionByOwner,
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                    variables: {owner: user.username}
                }
            );
            const sites = planData.data.xeroSiteConnectionByOwner.items.filter( (site) => {return site.siteConnectionStatus === 'active'});
            setSites(sites);
        } catch (err) {
            console.log('error fetching plans')
        } finally {
            setIsLoadingSites(false);
        }
    }, [sites, user]);

    useEffect(() => {
        const fetchConnections = async () => {
            if (connections.length === 0) {
                setIsLoadingConnections(true);
            }
            try {
                const connectionData = await API.graphql({
                        query: xeroConnectionByOwner,
                        authMode: 'AMAZON_COGNITO_USER_POOLS',
                        variables: {owner: user.username}
                    }
                );
                const connections = connectionData.data.xeroConnectionByOwner.items
                setConnections(connections)
            } catch (err) {
                console.log('error fetching connections')
            } finally {
                setIsLoadingConnections(false);
            }
        }
        const fetchPlans = async () => {
            if (plans.length === 0) {
                setIsLoadingPlans(true);
            }
            try {
                const planData = await API.graphql({
                        query: planByOwner,
                        authMode: 'AMAZON_COGNITO_USER_POOLS',
                        variables: {owner: user.username}
                    }
                );
                const plans = planData.data.planByOwner.items
                setPlans(plans)
            } catch (err) {
                console.log('error fetching plans')
            } finally {
                setIsLoadingPlans(false);
            }
        }
        fetchSites().catch(console.error);
        fetchConnections().catch(console.error);
        fetchPlans().catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSiteConnectionAdd = async(input, setFormErrorState) => {
        setFormErrorState('');
        try {
            await API.graphql({
                query:    addSiteConnection,
                variables: {input},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            fetchSites().catch(console.error);
        } catch (err) {
            const consolidatedErrors = err.errors.map(function(elem){return elem.message;}).join(",");
            setFormErrorState(consolidatedErrors);
        }
    }

    const handleSiteDisconnectClick= async(apiKey, setOpen, setIsDeleting) => {
        setListErrorState('');
        try {
            await API.graphql({
                query:    removeSiteConnection,
                variables: {foomanApiKey: apiKey},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            setSites(sites.filter(site => site.foomanApiKey !== apiKey));
        } catch (err) {
            const consolidatedErrors = err.errors.map(function(elem){return elem.message;}).join(",");
            setListErrorState(consolidatedErrors)
        } finally {
            setIsDeleting(false);
            setOpen(false);
        }
        fetchSites().catch(console.error);
    }

    const content = isLoadingSites ?
        <LoadingContent/>:
        (
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
                <ul className="divide-y divide-gray-200">
                    {sites.map((site) => (
                        <li key={site.foomanApiKey}>
                            <div className="block hover:bg-gray-50 px-4 py-4 ">

                                <div className="flex items-center justify-between ">
                                    <div>
                                        <div className="pb-4 flex flex-row space-x-4">
                                            <div className="font-bold" data-test="apikey-tenant">{site.tenantName}</div>
                                            <div className="font-medium text-gray-600">
                                                {site.siteConnectionStatus === 'active' ?
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {site.siteConnectionStatus}
                                                    </span> :
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                                        {site.siteConnectionStatus}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex flex-row text-gray-500">
                                            <div className="w-24">API Key: </div>
                                            <div className="font-medium" data-test="apikey-key">{site.foomanApiKey}</div>
                                            <div className="ml-2 text-sm"><CopyButton copyContent={site.foomanApiKey} /></div>
                                        </div>
                                        <div className="flex flex-row text-gray-500">
                                            <div className="w-24">API Secret: </div>
                                            <div className="font-medium" data-test="apikey-secret">{site.foomanApiSecret}</div>
                                            <div className="ml-2 text-sm"><CopyButton copyContent={site.foomanApiSecret} /></div>
                                        </div>
                                    </div>
                                    <div className="ml-2 flex-shrink-0 flex">
                                        <SiteConnectionRemoveButton disconnectHandler={handleSiteDisconnectClick} foomanApiKey={site.foomanApiKey}/>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );

    const addSiteConnections = !isLoadingConnections && !isLoadingPlans  && !isLoadingSites
        ? <AddSiteConnection connections={connections} plans={plans} sites={sites} addSiteConnectionHandler={handleSiteConnectionAdd}/>
        : null;

    return (
        <Fragment>
            <header className="bg-white">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg leading-6 font-semibold text-gray-900">API Keys</h1>
                </div>
            </header>
            <main>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="px-4 py-4 sm:px-0">
                        <div className="" >
                            <Message type="error" text={listErrorState}/>
                            <div className="bg-white px-4 py-5 sm:px-6">
                                {content}
                            </div>
                            {addSiteConnections}
                            <p className="text-sm text-gray-500 text-center">Xero Demo Companies are not counted towards your plan.</p>
                        </div>
                    </div>
                </div>
            </main>
        </Fragment>
    );
}

export default Sites;