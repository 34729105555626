import React, {useCallback, useEffect, useState} from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {shape, string} from "prop-types";
import {CheckIcon, DocumentDuplicateIcon} from "@heroicons/react/outline";

const CopyButton = props => {
    const [commandCopyForElement, setCommandCopyForElement] = useState(false)

    const {copyContent, buttonTitle} = props;
    const finalButtonTitle = buttonTitle ? buttonTitle : "Click to copy";

    const handleCopyClick = useCallback(() => {
        if (commandCopyForElement === false)
            setCommandCopyForElement(true);
    },[commandCopyForElement, setCommandCopyForElement]);

    useEffect(() => {
        if (commandCopyForElement !== false) {
            setTimeout(() => {
                setCommandCopyForElement(false);
            }, 5000);
        }
    },[commandCopyForElement, setCommandCopyForElement]);

    return (
        <div className="">
        <CopyToClipboard text={copyContent}>
            <button
                title={finalButtonTitle}
                onClick={handleCopyClick}
            >
            <span>
                {commandCopyForElement ?
                    <CheckIcon/> : <DocumentDuplicateIcon/>
                }
            </span>
            </button>
        </CopyToClipboard>
        </div>
    );
}

CopyButton.propTypes = {
    classes: shape({
        copyBtnRoot: string,
        faCopy: string
    }),
    copyContent: string.isRequired,
    buttonTitle: string
};

export default CopyButton;
