import React, { Fragment, useState, useEffect} from 'react';
import { Amplify, API } from 'aws-amplify';
import awsConfig from "../../aws-exports-adjusted";
import LoadingContent from "../Loading/LoadingContent";
import {xeroConnections} from '../../graphql/queries';
import XeroConnectButton from "../XeroConnectButton";
import XeroDisconnectButton from "../XeroDisconnectButton";
import {removeConnection} from "../../graphql/mutations";
import Message from '../Message';
import {useLocation} from "react-router-dom";

Amplify.configure(awsConfig);

const Connections = (props) => {
    const [isLoadingConnections, setIsLoadingConnections] = useState(false);
    const [connections, setConnections] = useState([]);
    const [queryParams, setQueryParams] = useState([]);
    const [formErrorState, setFormErrorState] = useState('');
    const location = useLocation();

    useEffect(() => {
        const fetchConnections = async () => {
            if (connections.length === 0) {
                setIsLoadingConnections(true);
            }
            try {
                const connectionData = await API.graphql({
                        query: xeroConnections,
                        authMode: 'AMAZON_COGNITO_USER_POOLS'
                    }
                );
                console.log(connectionData);
                const connections = connectionData.data.xeroConnections
                setConnections(connections)
            } catch (err) {
                console.log('error fetching connections')
            } finally {
                setIsLoadingConnections(false);
            }
        };
        fetchConnections().catch(console.error);
        const parsedParams = new URLSearchParams(location.search);
        setQueryParams(Object.fromEntries([...parsedParams]));
        location.search = null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDisconnectClick= async(tenantId, setOpen) => {
        try {
            await API.graphql({
                query:    removeConnection,
                variables: {tenantId: tenantId},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            setConnections(connections.filter(connection => connection.tenantId !== tenantId));
        } catch (err) {
            console.log(err);
            const displayErrors = [];
            if (err && err.errors) {
                err.errors.forEach((element) => {
                    if (element['message']) {
                        displayErrors.push(element['message']);
                    }
                });
            }
            setFormErrorState(displayErrors.join(' '));
        } finally {
            setOpen(false);
        }
    }

    const connectionList = isLoadingConnections ?
        <LoadingContent/>:
        connections.map((connection) => (
                <li key={connection.tenantId}>
                    <div className="block hover:bg-gray-50">
                        <div className="px-4 py-4 sm:px-6">
                            <div className="flex items-center justify-between">
                                <p className="text-sm font-medium truncate">{connection.tenantName}</p>
                                <div className="ml-2 flex-shrink-0 flex">
                                    {connection.connectionStatus === 'OK' ?
                                        <XeroDisconnectButton disconnectHandler={handleDisconnectClick}
                                                              tenantId={connection.tenantId}/> :
                                        <div className="inline-flex" >
                                            <Message type="error" text= 'Connection requires re-authentication'/>
                                            <XeroConnectButton />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="mt-2 sm:flex sm:justify-between">
                                {connection.isDemoOrg?
                                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                        Demo Account
                                    </p>:
                                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                        Live Account
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </li>
            ));

    return (
        <Fragment>
            <header className="bg-white">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg leading-6 font-semibold text-gray-900">Connected Xero Organisations</h1>
                </div>
            </header>
            <main>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="px-4 py-4 sm:px-0">
                        <div className="bg-white" >
                            <div className="bg-white shadow">
                                <ul className="divide-y divide-gray-200">
                                    <Message type={queryParams.status} text={queryParams.msg}/>
                                    {connectionList}
                                </ul>
                            </div>
                            <div className="py-10 flex flex-row">
                                <XeroConnectButton />
                            </div>
                            <Message type="error" text={formErrorState}/>
                        </div>
                    </div>
                </div>
            </main>
        </Fragment>
    );
}

export default Connections;