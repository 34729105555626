import React, {Fragment, useState, useMemo} from 'react';
import { useForm } from "react-hook-form";
import Message from "../Message";

const AddSiteConnection = (props) => {
    const {connections, plans, addSiteConnectionHandler, sites } = props;
    const { register, handleSubmit } = useForm();
    const [formErrorState, setFormErrorState] = useState('');
    const onSubmit = data => addSiteConnectionHandler(data, setFormErrorState);

    const unconnectedConnections = useMemo(() => {
        const result = [];
        connections.forEach((connection) => {
            const alreadyConnecteSites = sites.filter((siteConnection) => siteConnection.tenantId === connection.tenantId)
            if (alreadyConnecteSites.length === 0) {
                result.push(connection);
            }
        });
        return result;
    },[connections, sites]);

    if (connections.length === 0 && sites.length === 0) {
        return (
            <Fragment>
                <div >
                    <div className="min-w-0 flex-1 px-20 py-6">
                        <Message type="warning" text="Please add an additional Connection first."/>
                    </div>
                </div>
            </Fragment>
        );
    }

    if (plans.length === 0) {
        return (
            <Fragment>
                <div >
                    <div className="min-w-0 flex-1 px-20 py-6">
                        <Message type="error" text="Please add a Plan first."/>
                    </div>
                </div>
            </Fragment>
        );
    }

    if (connections.length <= sites.length) {
        return null;
    }

    const buttonContent  =
        <button
            type="submit"
            data-test="apikey-generate-button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm hover:text-white bg-foomanyellow hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            Generate new API Credentials for
        </button>;

    const connectionInput = unconnectedConnections.length > 1 ?
        <div
            className="inline-block relative">
            <label htmlFor="tenantId" className="sr-only">Xero Organisation</label>
            <select
                data-test="apikey-connection-select"
                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                name="tenantId" {...register("tenantId")}>
                {unconnectedConnections.map((connection) => (
                    <option key={connection.tenantId} value={connection.tenantId}>{connection.tenantName}</option>
                ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
            </div>
        </div>
        : <div
            className="inline-block relative">{unconnectedConnections[0].tenantName}<input type="hidden" name="tenantId" value={unconnectedConnections[0].tenantId} {...register("tenantId")}/>
        </div>;

    const planInput = plans.length > 1 ?
        <div
            className="inline-block relative">
            <label htmlFor="foomanPlanId" className="sr-only">Plan to use</label>
            <select
                data-test="apikey-plan-select"
                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                name="foomanPlanId" {...register("foomanPlanId")}>
                {plans.map((plan) => (
                    <option key={plan.foomanPlanId} value={plan.foomanPlanId}>{plan.planName} {plan.associatedDomain && '['+plan.associatedDomain+']'}</option>
                ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
            </div>
        </div>
        : <input type="hidden" name="foomanPlanId" value={plans[0].foomanPlanId} {...register("foomanPlanId")}/>;

    return (
        <Fragment>
            <div >
                <div className="min-w-0 flex-1 px-20 py-6">
                    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-center lg:flex-row lg:space-x-4 lg:space-y-0 space-y-4">
                        <div className="flex-shrink-0 flex justify-center">
                            {buttonContent}
                        </div>
                        {connectionInput}
                        {planInput}
                    </form>
                    <Message type="error" text={formErrorState}/>
                </div>
            </div>
        </Fragment>
    );
}

export default AddSiteConnection;