//import logo from './logo.svg';
import React, { Fragment } from 'react';
import './tailwind.generated.css';
import { Amplify } from 'aws-amplify';
import awsConfig from './aws-exports-adjusted';
import XeroLoginButton from "./components/XeroLoginButton";
import { useUser } from './components/User/user';
import {useLoading} from './components/Loading/LoadingContext';
import {UserLoadingScreen} from './components/Loading/UserLoadingScreen';
import { Routes, Route} from "react-router-dom";
import {RequireAuth, HideLoginFromUsers}  from "./components/Navigation/RequireAuth"
import Dashboard from "./components/Pages/Dashboard";
import Plans from "./components/Pages/Plans";
import Connections from "./components/Pages/Connections";
import Sites from "./components/Pages/Sites";
import Logs from "./components/Pages/Logs";
import LoggedInPageLayout from "./components/Pages/LoggedInPageLayout";

Amplify.configure(awsConfig);

function App() {
    const { login} = useUser();
    const {isLoading} = useLoading();
    const Home = () => (
            <Fragment>
                <XeroLoginButton handleLoginClick={login}/>
            </Fragment>
        );

    const content = isLoading ?
        <UserLoadingScreen/> :
        <Fragment>
            <Routes>

                <Route
                    path="/dashboard"
                    element={
                        <RequireAuth>
                            <LoggedInPageLayout>
                                <Dashboard />
                            </LoggedInPageLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/plans"
                    element={
                        <RequireAuth>
                            <LoggedInPageLayout>
                                <Plans />
                            </LoggedInPageLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/connections"
                    element={
                        <RequireAuth>
                            <LoggedInPageLayout>
                                <Connections />
                            </LoggedInPageLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/apikeys"
                    element={
                        <RequireAuth>
                            <LoggedInPageLayout>
                                <Sites />
                            </LoggedInPageLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/logs"
                    element={
                        <RequireAuth>
                            <LoggedInPageLayout>
                                <Logs />
                            </LoggedInPageLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/"
                    element={
                        <HideLoginFromUsers>
                            <Home />
                        </HideLoginFromUsers>
                    }
                />
            </Routes>
        </Fragment>;

    return (
        content
    );
};

export default App;