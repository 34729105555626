import React from "react";
import { Dialog } from '@headlessui/react';
import {useAlert} from "./useAlert";

const Alert = () => {
    const alert = useAlert();
    if (alert.alert === 'none') {
        return null;
    }
    return (
        <Dialog
            open={true}
            onClose={() => alert.clear()}
            className="fixed z-10 inset-0 overflow-y-auto"
        >
            <div className="flex items-center justify-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                <div className="relative bg-white rounded max-w-sm mx-auto p-8">
                    <Dialog.Title className="text-xl">{alert.alertText}</Dialog.Title>
                    <button
                        className="border-black border-solid border rounded mx-2 mt-8 py-1 px-2"
                        onClick={() => alert.clear()}
                    >
                        Dismiss
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

export default Alert;
