import React, {createContext, useState} from "react";
const AlertContext = createContext(null);

const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState('none');
    const [alertText, setAlertText] = useState(null);
    return (
        <AlertContext.Provider
            value={{
                alert: alert,
                alertText: alertText,
                success: (text, timeout) => {
                    setAlertText(text);
                    setAlert('success');
                    setTimeout(() => {
                        setAlert('none');
                    }, timeout * 1000 || 10000)
                },
                error: (text, timeout) => {
                    setAlertText(text);
                    setAlert('error');
                    setTimeout(() => {
                        setAlert('none');
                    }, timeout * 1000 || 10000)
                },
                clear: () => (setAlert('none')),
            }}
        >
            {children}
        </AlertContext.Provider>
    );
}

export { AlertProvider };
export default AlertContext;