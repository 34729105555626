import React, {Fragment} from 'react';
import ConnectXeroButton from './connect-blue.svg';
import {API} from "aws-amplify";
import {xeroConsentUrlDataAccess} from "../../graphql/queries";
import {useAlert} from "../Alert/useAlert";
import {useLoading} from "../Loading/LoadingContext";

const handleConnectClick= async(alert, setLoading) => {
    setLoading("Redirecting to Xero");
    let url;
    try {
        url = await API.graphql({
            query:    xeroConsentUrlDataAccess,
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
    } catch (e) {
        setLoading(false);
        alert.error('Please try again');
        return;
    }
    if (!url || !url.data || !url.data.xeroConsentUrlDataAccess) {
        setLoading(false);
        alert.error('Please try again');
        return;
    }
    window.location = url.data.xeroConsentUrlDataAccess;
}

const XeroConnectButton = (props) => {
    const alert = useAlert();
    const {setLoading} = useLoading();
    return (
        <Fragment>
            <button
                data-test="connect-xero-button"
                onClick={() => handleConnectClick(alert, setLoading)}
                type="button">
                <img src={ConnectXeroButton} alt="Connect to Xero"/>
            </button>
        </Fragment>
    );
};

export default XeroConnectButton;
