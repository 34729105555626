import React, {Fragment} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon, UserIcon } from '@heroicons/react/outline'
import {useUser} from "../User/user";
import Alert from '../Alert';
import { ReactComponent as Logo } from "../../logo.svg";
import PrivacyLink from "../Privacy/PrivacyLink";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const LoggedInPageLayout = (props) => {
    const { user, logout } = useUser();
    let navigate = useNavigate();
    const location = useLocation();

    const navigation = [
        { name: 'Dashboard', href: '/dashboard', current: location.pathname === '/dashboard' },
        { name: 'Connections', href: '/connections', current: location.pathname === '/connections' },
        { name: 'Plans', href: '/plans', current: location.pathname === '/plans' },
        { name: 'API Keys', href: '/apikeys', current: location.pathname === '/apikeys' },
        { name: 'Logs', href: '/logs', current: location.pathname === '/logs' }
    ];

    const templateUser = {
        name: user.attributes.given_name + ' ' + user.attributes.family_name
    }
    const userNavigation = [
        //{ name: 'Your Profile', onclick: '/profile' },
        { name: 'Sign out', onclick: logout }
    ]

    const handleLinkClick = (e, destination)=> {
        e.stopPropagation();
        navigate(destination);
    }

    return (
<div className="min-h-full text-foomantext">
    <Disclosure as="nav" className="bg-white">
        {({ open }) => (
            <>
                <div className="max-w-7xl h-15 mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <Logo className="h-10" alt="Fooman Logo"/>
                            </div>
                            <div className="hidden lg:block">
                                <div className="ml-5 lg:ml-10 flex items-baseline space-x-2 lg:space-x-3">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            onClick={e => handleLinkClick(e, item.href)}
                                            data-test={"MenuItem-" + item.name}
                                            className={classNames(
                                                item.current
                                                    ? 'bg-gray-900 text-white uppercase tracking-wider'
                                                    : 'text-black hover:text-foomanyellow uppercase tracking-wider',
                                                'lg:px-3 px-1 py-2 rounded-md text-sm font-medium'
                                            )}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="hidden lg:block">
                            <div className="ml-4 flex items-center lg:ml-6">

                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-3 relative">
                                    <div className="flex flex-row">
                                        <div className="mr-2" data-test="user-email">{user.attributes?user.attributes.email:''}</div>
                                        <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                            <span className="sr-only">Open user menu</span>
                                            <UserIcon className="block h-6 w-6 rounded-full" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {userNavigation.map((item) => (
                                                <Menu.Item key={item.name}>
                                                    {({ active }) => (
                                                        <button
                                                            onClick={item.onclick}
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm text-gray-700'
                                                            )}
                                                        >
                                                            {item.name}
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                        <div className="-mr-2 flex lg:hidden">
                            {/* Mobile menu button */}
                            <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                <span className="sr-only">Open main menu</span>
                                {open ? (
                                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                                ) : (
                                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                )}
                            </Disclosure.Button>
                        </div>
                    </div>
                </div>

                <Disclosure.Panel className="lg:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        {navigation.map((item) => (
                            <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                onClick={e => handleLinkClick(e, item.href)}
                                className={classNames(
                                    item.current ? 'bg-gray-900 text-white uppercase tracking-wider' : 'text-black hover:text-foomanyellow uppercase tracking-wider',
                                    'block px-3 py-2 rounded-md text-base font-medium'
                                )}
                                aria-current={item.current ? 'page' : undefined}
                            >
                                {item.name}
                            </Disclosure.Button>
                        ))}
                    </div>
                    <div className="pt-4 pb-3 border-t border-gray-700">
                        <div className="flex items-center px-5">
                            <div className="ml-3">
                                <div className="text-base font-medium text-white">{ templateUser.name}</div>
                                <div className="text-sm font-medium text-gray-400" >{ templateUser.email}</div>
                            </div>
                        </div>
                        <div className="mt-3 px-2 space-y-1">
                            {userNavigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </div>
                </Disclosure.Panel>
                {props.children}

            </>
        )}
    </Disclosure>
    <Alert />
    <div className="sticky top-[100vh] p-4 flex justify-center items-center">
        <div className=""><PrivacyLink/></div>
    </div>
</div>
    );
}

export default LoggedInPageLayout;