import React, {useEffect} from 'react';
import {Auth, Hub} from 'aws-amplify';
import awsConfig from './../../aws-exports-adjusted';
import { useLoading } from "../Loading/LoadingContext";
import { useNavigate, useLocation } from 'react-router-dom'
//TODO
//token refresh
//https://docs.amplify.aws/lib/auth/advanced/q/platform/js/#token-refresh
Auth.configure(awsConfig);

// Create a context that will hold the values that we are going to expose to our components.
// Don't worry about the `null` value. It's gonna be *instantly* overriden by the component below
export const UserContext = React.createContext(null);

// Create a "controller" component that will calculate all the data that we need to give to our
// components bellow via the `UserContext.Provider` component. This is where the Amplify will be
// mapped to a different interface, the one that we are going to expose to the rest of the app.
export const UserProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);
    const { setLoading } = useLoading();
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        const getUser = () => {
            setLoading("Getting User Details");
            return Auth.currentAuthenticatedUser()
                .then(userData => userData)
                .catch(() => {
                    console.log('Not signed in');
                    setLoading(false);
                });
        }
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    getUser().then(userData => setUser(userData));
                    navigate(state?.path || "/dashboard");
                    break;
                case 'signOut':
                    setUser(null);
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.log('Sign in failure');
                    break;
                case 'oAuthSignOut':
                    break;
                default:
                    break;
            }
        });

        getUser().then(userData => {
            setUser(userData);
            setLoading(false)}
        );
    }, [navigate, setLoading, state?.path]);

    const eraseCookie = (name) => {
        document.cookie = `${name}=; Max-Age=-99999999;`;
    };

    const login = (signup=false) => {
        const cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++)
            eraseCookie(cookies[i].split("=")[0]);
        setLoading("Logging in with Xero");
        if (signup) {
            Auth.federatedSignIn({customProvider: 'XeroSignUp'});
        } else {
            Auth.federatedSignIn({customProvider: 'Xero'});
        }
    }

    const logout = () => {
        setLoading("Logging out");
        Auth.signOut().then(data => {
            setUser(null);
            return data;
        });
    }


    // Make sure to not force a re-render on the components that are reading these values,
    // unless the `user` value has changed. This is an optimisation that is mostly needed in cases
    // where the parent of the current component re-renders and thus the current component is forced
    // to re-render as well. If it does, we want to make sure to give the `UserContext.Provider` the
    // same value as long as the user data is the same. If you have multiple other "controller"
    // components or Providers above this component, then this will be a performance booster.
    const values = React.useMemo(() => ({ user, login, logout }), [user]);

    // Finally, return the interface that we want to expose to our other components
    return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abtracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useUser = () => {
    const context = React.useContext(UserContext);

    if(context === undefined) {
        throw new Error('`useUser` hook must be used within a `UserProvider` component');
    }
    return context;
};