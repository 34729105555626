import React, {Fragment, useState, useEffect} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import textfile from "./privacy.txt";

const PrivacyLink = (props) => {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState("Loading...");

    useEffect(() => {
        fetch(textfile)
            .then((response) => response.text())
            .then((textContent) => {
                setText(textContent);
            });
        return text || "Loading...";
    }, [text]);

    return (
        <Fragment>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div data-test="terms-modal"
                                className="z-20 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-y-scroll max-h-[80vh] shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title as="h3" data-test="terms-modal-title" className="text-lg leading-6 font-medium text-gray-900 h-10 flex flex-row items-center">
                                            Fooman - Privacy Policy
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <pre className="text-sm text-gray-500 whitespace-pre-wrap">
                                                {text}
                                            </pre>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse h-10">
                                    <button type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-700 text-white text-base font-medium hover:text-black hover:bg-foomanyellow focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}>Close</button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
           <a className="underline" data-test="open-privacy-link" onClick={() => setOpen(true)} >Privacy Policy</a>
        </Fragment>
    );
};

export default PrivacyLink;
