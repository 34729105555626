import React, {Fragment, useEffect, useState} from 'react';
import {API} from "aws-amplify";
import {getUsage} from "../../graphql/queries";
import {useUser} from "../User/user";
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
     LineChart,
    //BarChart,
    // PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
    // GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts';

import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    // ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    // DataZoomComponent,
    // DataZoomInsideComponent,
    // DataZoomSliderComponent,
    // VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    // TransformComponent,
    DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';
import LoadingContent from "../Loading/LoadingContent";

echarts.use(
    [TitleComponent, LegendComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer]
);

const UsageLog = (props) => {
    const [isLoadingUsage, setIsLoadingUsage] = useState(false);
    const [usage, setUsage] = useState([]);
    const {user} = useUser();

    useEffect(() => {
        const fetchUsage = async () => {
            if (usage.length === 0) {
                setIsLoadingUsage(true);
            }
            try {
                const usageData = await API.graphql({
                        query: getUsage,
                        authMode: 'AMAZON_COGNITO_USER_POOLS'
                    }
                );
                setUsage(usageData.data.getUsage);
            } catch (err) {
                console.log('error fetching usage')
            } finally {
                setIsLoadingUsage(false);
            }
        };
        fetchUsage().catch(console.error);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getChartOptions = (data) => {
        const options = {
            xAxis: {
                type: 'category',
                data: data.map((x) => x['label'])
            },
            yAxis: [
                {
                    type: 'value'
                },
                {
                    type: 'value'
                }
            ],
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                orient: 'horizontal',
                top: 0
            },
            series: [
                {
                    name: 'Used',
                    data: data.map((x) => x['used']),
                    type: 'line',
                    yAxisIndex: 0,
                },
                {
                    name: 'Remaining',
                    data: data.map((x) => x['remaining']),
                    type: 'line',
                    yAxisIndex: 1,
                }
            ]
        };
        return options;
    };

    const usageList = isLoadingUsage ?
        <LoadingContent/>:
        usage.map((entry) => (
            entry.data.length > 0 ?
            <div key={entry.label}>
                <p className="text-sm font-medium truncate">{entry.label}</p>
                <div className="ml-2 flex-shrink-0 flex">
                    <ReactEChartsCore
                        style={{height: '300px', width: '100%'}}
                        echarts={echarts}
                        option={getChartOptions(entry.data)}
                    />
                </div>
            </div>: null
        ));

    return (
        <Fragment>
            {usageList}
        </Fragment>
    );
};

export default UsageLog;
