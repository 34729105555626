/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const xeroConsentUrlDataAccess = /* GraphQL */ `
  query XeroConsentUrlDataAccess {
    xeroConsentUrlDataAccess
  }
`;
export const xeroConnections = /* GraphQL */ `
  query XeroConnections {
    xeroConnections {
      tenantId
      owner
      xeroConnectionId
      authEventId
      tenantName
      isDemoOrg
      connectionStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getUsage = /* GraphQL */ `
  query GetUsage {
    getUsage {
      label
      data {
        date
        label
        used
        remaining
        __typename
      }
      __typename
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($foomanPlanId: String!) {
    getPlan(foomanPlanId: $foomanPlanId) {
      foomanPlanId
      owner
      xeroSubscriptionId
      foomanSerialNumber
      planName
      planCode
      planStatus
      includedSiteConnections
      includedRequestsPerMonth
      associatedDomain
      usagePlanId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $foomanPlanId: String
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPlans(
      foomanPlanId: $foomanPlanId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        foomanPlanId
        owner
        xeroSubscriptionId
        foomanSerialNumber
        planName
        planCode
        planStatus
        includedSiteConnections
        includedRequestsPerMonth
        associatedDomain
        usagePlanId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getXeroConnection = /* GraphQL */ `
  query GetXeroConnection($tenantId: String!) {
    getXeroConnection(tenantId: $tenantId) {
      tenantId
      owner
      xeroConnectionId
      authEventId
      tenantName
      isDemoOrg
      connectionStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listXeroConnections = /* GraphQL */ `
  query ListXeroConnections(
    $tenantId: String
    $filter: ModelXeroConnectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listXeroConnections(
      tenantId: $tenantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        owner
        xeroConnectionId
        authEventId
        tenantName
        isDemoOrg
        connectionStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getXeroSiteConnection = /* GraphQL */ `
  query GetXeroSiteConnection($foomanApiKey: String!) {
    getXeroSiteConnection(foomanApiKey: $foomanApiKey) {
      foomanApiKey
      siteConnectionStatus
      foomanApiSecret
      apiKeyId
      usagePlanId
      foomanPlanId
      owner
      tenantId
      tenantName
      isDemoOrg
      associatedDomain
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listXeroSiteConnections = /* GraphQL */ `
  query ListXeroSiteConnections(
    $foomanApiKey: String
    $filter: ModelXeroSiteConnectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listXeroSiteConnections(
      foomanApiKey: $foomanApiKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        foomanApiKey
        siteConnectionStatus
        foomanApiSecret
        apiKeyId
        usagePlanId
        foomanPlanId
        owner
        tenantId
        tenantName
        isDemoOrg
        associatedDomain
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getXeroOnboarding = /* GraphQL */ `
  query GetXeroOnboarding($owner: String!) {
    getXeroOnboarding(owner: $owner) {
      owner
      usedSignUpWithXero
      termsAccepted
      connectionCreated
      planAdded
      apiCredentialsCreated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listXeroOnboardings = /* GraphQL */ `
  query ListXeroOnboardings(
    $owner: String
    $filter: ModelXeroOnboardingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listXeroOnboardings(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        usedSignUpWithXero
        termsAccepted
        connectionCreated
        planAdded
        apiCredentialsCreated
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActionLog = /* GraphQL */ `
  query GetActionLog($id: String!) {
    getActionLog(id: $id) {
      id
      status
      owner
      type
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listActionLogs = /* GraphQL */ `
  query ListActionLogs(
    $id: String
    $filter: ModelActionLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listActionLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        owner
        type
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const planByOwner = /* GraphQL */ `
  query PlanByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    planByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        foomanPlanId
        owner
        xeroSubscriptionId
        foomanSerialNumber
        planName
        planCode
        planStatus
        includedSiteConnections
        includedRequestsPerMonth
        associatedDomain
        usagePlanId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const xeroConnectionByOwner = /* GraphQL */ `
  query XeroConnectionByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelXeroConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    xeroConnectionByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        owner
        xeroConnectionId
        authEventId
        tenantName
        isDemoOrg
        connectionStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const siteConnectionByPlanAndStatus = /* GraphQL */ `
  query SiteConnectionByPlanAndStatus(
    $foomanPlanId: String!
    $siteConnectionStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelXeroSiteConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteConnectionByPlanAndStatus(
      foomanPlanId: $foomanPlanId
      siteConnectionStatus: $siteConnectionStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        foomanApiKey
        siteConnectionStatus
        foomanApiSecret
        apiKeyId
        usagePlanId
        foomanPlanId
        owner
        tenantId
        tenantName
        isDemoOrg
        associatedDomain
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const xeroSiteConnectionByOwner = /* GraphQL */ `
  query XeroSiteConnectionByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelXeroSiteConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    xeroSiteConnectionByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        foomanApiKey
        siteConnectionStatus
        foomanApiSecret
        apiKeyId
        usagePlanId
        foomanPlanId
        owner
        tenantId
        tenantName
        isDemoOrg
        associatedDomain
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const onboardingByCreatedAt = /* GraphQL */ `
  query OnboardingByCreatedAt(
    $createdAt: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelXeroOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    onboardingByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        usedSignUpWithXero
        termsAccepted
        connectionCreated
        planAdded
        apiCredentialsCreated
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const actionLogByOwner = /* GraphQL */ `
  query ActionLogByOwner(
    $owner: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    actionLogByOwner(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        owner
        type
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const invoicesByOrganisation = /* GraphQL */ `
  query InvoicesByOrganisation(
    $tenantId: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelXeroPaidInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByOrganisation(
      tenantId: $tenantId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        xeroInvoiceId
        xeroInvoiceNumber
        tenantId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subscriptionByOrganisation = /* GraphQL */ `
  query SubscriptionByOrganisation(
    $tenantId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelXeroSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionByOrganisation(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        xeroSubscriptionId
        owner
        status
        tenantId
        createdAt
        currentPeriodEnd
        endDate
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
