import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Amplify, API } from 'aws-amplify';
import { addPlan } from '../../graphql/mutations';
import {listXeroOnboardings, planByOwner} from '../../graphql/queries';
import awsConfig from "../../aws-exports-adjusted";
import LoadingContent from "../Loading/LoadingContent";
import AddPlan from "../Plan/AddPlan";
import Message from "../Message";
import {useUser} from "../User/user";
Amplify.configure(awsConfig);
const initialState = { foomanSerialNumber: '' };

const SIGN_UP_LINK_ID = process.env.REACT_APP_XERO_SUBSCRIBE_ID ? process.env.REACT_APP_XERO_SUBSCRIBE_ID : '86916702-6992-4611-ab5a-7feae8bc1966';

const Plans = (props) => {
    const [formState, setFormState] = useState(initialState);
    const [isLoadingPlans, setIsLoadingPlans] = useState(false);
    const [isLoadingOnboarding, setIsLoadingOnboarding] = useState(false);
    const [formErrorState, setFormErrorState] = useState('');
    const [plans, setPlans] = useState([]);
    const [isXeroSignUpUser, setIsXeroSignUpUser] = useState(false);
    const {user} = useUser();

    const fetchPlans = useCallback(async () => {
        if (plans.length === 0) {
            setIsLoadingPlans(true);
        }
        try {
            const planData = await API.graphql({
                    query: planByOwner,
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                    variables: {owner: user.username}
                }
            );
            const plans = planData.data.planByOwner.items
            setPlans(plans)
        } catch (err) {
            console.log('error fetching plans')
        } finally {
            setIsLoadingPlans(false);
        }
    }, [plans, user]);

    useEffect(() => {
        const fetchOnboarding = async () => {
            setIsLoadingOnboarding(true);
            try {
                const onboardingData = await API.graphql({
                        query: listXeroOnboardings,
                        authMode: 'AMAZON_COGNITO_USER_POOLS'
                    }
                );
                if (onboardingData.data.listXeroOnboardings.items.length > 0) {
                    setIsXeroSignUpUser(onboardingData.data.listXeroOnboardings.items[0].usedSignUpWithXero);
                }
            } catch (err) {
                console.log('error fetching accepted terms')
            } finally {
                setIsLoadingOnboarding(false);
            }
        }
        fetchOnboarding().catch(console.error);
        fetchPlans().catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setInput(key, value) {
        setFormState({ ...formState, [key]: value });
        setFormErrorState('');
    }

    async function handleAddPlanClick() {
        try {
            if (!formState.foomanSerialNumber) {
                return;
            }
            setFormErrorState();
            const plan = { ...formState };
            await API.graphql({
                query:    addPlan,
                variables: { ...plan},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            setFormState(initialState);
            await fetchPlans()
        } catch (err) {
            console.log(err);
            const displayErrors = [];
            if (err && err.errors) {
                err.errors.forEach((element) => {
                    if (element['message']) {
                        displayErrors.push(element['message']);
                    }
                });
            }
            setFormErrorState(displayErrors.join(' '));
        }
    }

    const maybeXeroDelayNotice = isXeroSignUpUser? <Message type='warning' text='Please note that plan updates at Xero will take 2-3 minutes to show here.' /> : null;

    const maybeSerialNumberInput = !isLoadingOnboarding ?
        <Fragment>
            <AddPlan handleAddPlanClick={handleAddPlanClick}
                     onChangeHandler={setInput}
                     value={formState.foomanSerialNumber}
            />
            <Message type="error" text={formErrorState}/>
        </Fragment>
        : null;

    const content = (isLoadingPlans || isLoadingOnboarding) ?
        <LoadingContent/>:
        (
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
                <ul className="divide-y divide-gray-200">
                    {plans.map((plan) => (
                        <li key={plan.foomanPlanId}>
                            <div className="block hover:bg-gray-50">
                                <div className="px-4 py-4 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <p className="font-bold text-gray-600 truncate"
                                           data-test="plan-name"
                                        >{plan.planName} <span className="font-medium text-sm">[API Requests {plan.includedRequestsPerMonth} / Month]</span></p>
                                    </div>
                                    <div className="mt-2 sm:flex sm:justify-between">
                                        {plan.planStatus === 'active' ?
                                            <p data-test="plan-status" className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                {plan.planStatus}
                                            </p> :
                                            <p data-test="plan-status" className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                                {plan.planStatus}
                                            </p>
                                        }
                                    </div>
                                    {plan.foomanSerialNumber ?
                                        <div className="py-4">
                                            <pre
                                                className="text-sm font-small text-gray-400">{plan.foomanSerialNumber}</pre>
                                        </div>
                                        : null
                                    }
                                </div>

                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );

    return (
        <Fragment>
            <header className="bg-white">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg leading-6 font-semibold text-gray-900">Plans</h1>
                </div>
            </header>
            <main>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="px-4 py-4 sm:px-0">
                        <div className="bg-white" >
                            {content}
                            {!isXeroSignUpUser ?
                                maybeSerialNumberInput
                                : <button
                                    type="submit"
                                    data-test="plan-subscribe"
                                    onClick={() => window.location.href = "https://apps.xero.com/subscribe/" + SIGN_UP_LINK_ID}
                                    className="inline-flex items-center mt-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm hover:text-white bg-foomanyellow hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                    Manage Plans at Xero
                                </button>
                            }
                        </div>
                        {maybeXeroDelayNotice}
                    </div>
                </div>
            </main>
        </Fragment>
    );
}

export default Plans;