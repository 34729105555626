import { createContext, useContext, useState } from "react";

const LoadingContext = createContext({
    isLoading: false,
    setLoading: null,
});

export function LoadingProvider({ children }) {
    let params = (new URL(document.location)).searchParams;
    const [isLoading, setLoading] = useState(params.has('code')?"Getting User Details":"Loading Page");
    const value = { isLoading, setLoading };
    return (
        <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
    );
}

export function useLoading() {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("useLoading must be used within LoadingProvider");
    }
    return context;
}