import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import {useUser} from "../User/user";

export const RequireAuth = ({ children }) => {
    const { user } = useUser();
    const location = useLocation();
    return user
        ? children
        : <Navigate
            to="/"
            replace
            state={{ path: location.pathname }}
        />;
}

export const HideLoginFromUsers = ({ children }) => {
    const { user } = useUser();
    return !user
        ? children
        : <Navigate
            to="/dashboard"
            replace
        />;
}
