/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addPlan = /* GraphQL */ `
  mutation AddPlan($foomanSerialNumber: String!) {
    addPlan(foomanSerialNumber: $foomanSerialNumber)
  }
`;
export const removeConnection = /* GraphQL */ `
  mutation RemoveConnection($tenantId: String!) {
    removeConnection(tenantId: $tenantId)
  }
`;
export const addSiteConnection = /* GraphQL */ `
  mutation AddSiteConnection($input: AddSiteConnectionInput!) {
    addSiteConnection(input: $input)
  }
`;
export const removeSiteConnection = /* GraphQL */ `
  mutation RemoveSiteConnection($foomanApiKey: String!) {
    removeSiteConnection(foomanApiKey: $foomanApiKey)
  }
`;
export const acceptTerms = /* GraphQL */ `
  mutation AcceptTerms {
    acceptTerms
  }
`;
