import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from "aws-amplify";
import awsConfig from './aws-exports-adjusted';
import {
    BrowserRouter
} from "react-router-dom";
import {UserProvider} from "./components/User/user";
import {LoadingProvider} from "./components/Loading/LoadingContext";
import {AlertProvider} from "./components/Alert/AlertContext";
Amplify.configure(awsConfig);

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <AlertProvider>
              <LoadingProvider>
                  <UserProvider>
                      <App />
                  </UserProvider>
              </LoadingProvider>
          </AlertProvider>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
