import React, { Fragment, useState, useEffect} from 'react';
import { Amplify, API } from 'aws-amplify';
import awsConfig from "../../aws-exports-adjusted";
import LoadingContent from "../Loading/LoadingContent";
import {actionLogByOwner} from '../../graphql/queries';
import {useUser} from "../User/user";
import UsageLog from "../UsageLog";
Amplify.configure(awsConfig);

const Logs = (props) => {
    const [isLoadingLogs, setIsLoadingLogs] = useState(false);
    const [logs, setLogs] = useState([]);
    const {user} = useUser();

    useEffect(() => {
        const fetchLogs = async () => {
            if (logs.length === 0) {
                setIsLoadingLogs(true);
            }
            try {
                const logData = await API.graphql({
                        query: actionLogByOwner,
                        authMode: 'AMAZON_COGNITO_USER_POOLS',
                        variables: {owner: user.username, sortDirection:'DESC'}
                    }
                );
                setLogs(logData.data.actionLogByOwner.items)
            } catch (err) {
                console.log('error fetching logs')
            } finally {
                setIsLoadingLogs(false);
            }
        }
        fetchLogs().catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const options = {year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric' };
    const logList = isLoadingLogs ?
        <LoadingContent/>:
        logs.map((entry) => (
                <li key={entry.id}>
                    <div className="block hover:bg-gray-50">
                        <div className="px-4 py-4 sm:px-6">
                            <div className="flex items-center space-x-4">
                                <p className="w-48 text-sm font-medium text-gray-600">{new Date(entry.createdAt).toLocaleDateString(undefined, options)}</p>
                                <p className="text-sm font-medium text-gray-600">{entry.content}</p>
                            </div>
                        </div>
                    </div>
                </li>
            ));

    return (
        <Fragment>
            <header className="bg-white">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg leading-6 font-semibold text-gray-900">Log</h1>
                </div>
            </header>
            <main>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="px-4 py-4 sm:px-0">
                        <UsageLog/>
                        <div className="bg-white" >
                            <div className="bg-white shadow overflow-hidden sm:rounded-md">
                                <ul className="divide-y divide-gray-200">
                                    {logList}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Fragment>
    );
}

export default Logs;